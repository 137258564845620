<template lang="pug">
  .inventory-group.d-flex.m-t-20
    .title.m-t-3
      | {{ $t("sync_list.inventory_group") }}
    .w-100.item-value(v-if="inventoryGroupName")
      | {{ inventoryGroupName }}
</template>

<script>
  export default {
    props: {
      inventoryGroupName: {
        type: String,
        default: ""
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .title
    color: $icon-color
    font-size: 0.8rem
    font-weight: 400
    width: 150px

  .item-value
    border-bottom: 1px solid $default-purple-light-line
    color: $default-black
    font-size: 0.8rem
    font-weight: 400
    padding: 5px 0
</style>
